import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vueWechatTitle from 'vue-wechat-title'
const uni = window.uni
const wx = window.wx
Vue.prototype.$uni = uni
Vue.prototype.$wx = wx
Vue.config.productionTip = false
Vue.use(vueWechatTitle)
const bus = new Vue()
Vue.prototype.$bus = bus

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
