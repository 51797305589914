import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PdfViewer',
    component: () => import(/* webpackChunkName: "pdfViewer" */ '../views/pdfViewer'),
    meta:{
      title: '乐驾学车'
    }
  },
  {
    path: '/contract',
    name: 'Contract',
    component: () => import(/* webpackChunkName: "contract" */ '../views/contract'),
    meta:{
      title: '乐驾学车'
    }
  },
  {
    path: '/contract/signature',
    name: 'Signature',
    component: () => import(/* webpackChunkName: "signature" */ '../views/contract/signature'),
    meta:{
      title: '电子签名'
    }
  },
  {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: () => import(/* webpackChunkName: "signature" */ '../views/auth_callback/index'),
    meta:{
      title: ''
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
